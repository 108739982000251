$(function() {
  const show_map = $('#show-map');
  const show_list = $('#show-list');
  const listing_ads = $('.listing-ads');
  const map_list_grid = $('.map-list-grid');

  const toggleTab = (activeTab) => {
    localStorage.setItem('activeTab', activeTab);
    const isMapTab = activeTab === 'map';

    show_map.toggleClass('btn-primary', isMapTab);
    show_list.toggleClass('btn-primary', !isMapTab);
    listing_ads.toggleClass('showing-map', isMapTab);
    map_list_grid.toggleClass('showing-map', isMapTab);
  };

  const activeTab = localStorage.getItem('activeTab') || 'map';
  toggleTab(activeTab);

  $(document).on('click', '#show-map', () => toggleTab('map'));
  $(document).on('click', '#show-list', () => toggleTab('list'));
});
