// Vérifiez si c'est une archive
const isArchive = document.body.classList.contains('archive');
// Ajoutez la valeur au champ caché de CF7
document.addEventListener('DOMContentLoaded', () => {

    const hiddenField = document.querySelector('input[name="is_archive"]');

    if (hiddenField) {
        hiddenField.value = isArchive ? 'true' : 'false';
    }
});
