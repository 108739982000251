class Calculator {

  constructor() {
    this.calculator = null;
  }

  initResult(monthlyPayment, contribution, loanCapacity, duration, taxRate, creditValue) {
    this.calculator = `
    <div class="calculator-results">
      <p>Avec une mensualité de <span class="calculator-informations"> ${this.formatValue(monthlyPayment)} €</span>, et un apport de <span class="calculator-informations"> ${this.formatValue(contribution)} € </span></p>
      <p>Vous pourrez emprunter <span class="calculator-informations"> ${this.formatValue(loanCapacity)} € </span></p>
      <p>Sur une durée de <span class="calculator-informations"> ${duration} </span> ans à un taux de <span class="calculator-informations"> ${taxRate}%</span></p>
      <p class="calculator-informations-important mb-3">Le coût du crédit est de <span class="calculator-informations"> ${this.formatValue(creditValue)} €</span></p>
    </div>
    <div>
      <h3 class="calculator-title-form">
        Vous voulez acheter ce bien ? <br>
        Contactez-nous pour obtenir votre étude personnalisée :
      </h3>
    </div>
    `;

    const form = $('#calculator-second-step form');
    form.find('input[name="monthlyPayment"]').val(this.formatValue(monthlyPayment));
    form.find('input[name="loanCapacity"]').val(this.formatValue(loanCapacity));
    form.find('input[name="duration"]').val(duration);
    form.find('input[name="taxRate"]').val(taxRate);
    form.find('input[name="creditValue"]').val(this.formatValue(creditValue));
    form.find('input[name="contribution"]').val(this.formatValue(contribution));
  }

  calculate(monthlyPayment, contribution, duration, taxRate) {
    monthlyPayment = parseFloat(monthlyPayment.replace(',', '.'));
    contribution = parseFloat(contribution.replace(',', '.'));
    duration = parseFloat(duration.replace(',', '.'));
    taxRate = parseFloat(taxRate.replace(',', '.'));

    const monthlyRate = taxRate / 100 / 12;
    const loanCapacity = monthlyPayment * ((1 - Math.pow(1 + monthlyRate, -duration * 12)) / monthlyRate);
    const creditCost = loanCapacity * (monthlyRate * duration * 12);
    const totalCapacity = loanCapacity + contribution;

    this.initResult(monthlyPayment, contribution, totalCapacity, duration, taxRate, creditCost);
    return this.calculator;
  }

  formatValue(amount) {
    const absAmount = Math.abs(Math.round(amount));
    return absAmount >= 1000 ? absAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : absAmount.toString();
  }
}

$(document).on('click', '#calculate', (e) => {
  e.preventDefault();
  const monthlyPayment = $('#monthly-payment').val();
  const contribution = $('#contribution').val();
  const duration = $('#duration').val();
  const taxRate = $('#tax-rate').val();

  $('#calculator-first-step').hide();

  const calculator = new Calculator();
  const output = calculator.calculate(monthlyPayment, contribution, duration, taxRate);
  $('#calculator-second-step').prepend(output);
  $('#calculator-second-step').show();
});
